import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { EnvService } from '../shared/services/env/env.service';
import { environment } from 'src/environments/environment';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private iss = environment.iss;

  constructor(
    private env: EnvService,
    @Inject(PLATFORM_ID) private platformId: {}
  ) {}

  public handle(token): boolean {
    this.set(token);
    return this.isValid();
  }

  public set(token): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('token', token);
    }
  }

  public get(): string {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem('token');
    }
    return '';
  }

  public remove(): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem('token');
    }
  }

  public loggedIn(): boolean {
    return this.isValid();
  }

  private isValid(): boolean {
    const token = this.get();
    if (token) {
      const _payload = this.payload(token);

      if (_payload) {
        return Object.values(this.iss).indexOf(_payload.iss) > -1
          ? true
          : false;
      }
    }

    return false;
  }

  private payload(token): any {
    const payload = token.split('.')[1];
    return this.decode(payload);
  }

  private decode(payload): string {
    return JSON.parse(atob(payload));
  }
}
