import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { LayoutModule } from 'src/app/layout/layout.module';
import { HomeComponent } from './home.component';
// RECOMMENDED
import { HomeService } from './home.service';
import { ComponentsModule } from 'src/app/shared/components/components.module';

@NgModule({
  declarations: [HomeComponent],
  imports: [
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ComponentsModule,
    RouterModule,
    LayoutModule,
  ],
  providers: [HomeService, DatePipe],
})
export class HomeModule {}
