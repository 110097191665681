import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { LayoutModule } from '../layout/layout.module';
import { MainPipe } from '../pipes/main-pipe.module';
import { HomeModule } from './home/home.module';

// accomodation

@NgModule({
  declarations: [],

  imports: [
    // RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    HomeModule,
    CommonModule,

    // AreasModule,
    // PlacesModule,
    LayoutModule,
    // BookingModule,
    CarouselModule,
    MainPipe,
    RouterModule,
    // LoginModule,
  ],
  exports: [],
})
export class AllModule {}
