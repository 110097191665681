import { ModuleWithProviders, NgModule } from '@angular/core';
import { MXV_APP_CONFIG } from './config.constants';
import { MxvConfigService } from './config.service';

@NgModule()
export class MxvConfigModule {
  /**
   * Constructor
   */
  constructor(private _configService: MxvConfigService) {}

  /**
   * forRoot method for setting user configuration
   *
   * @param config
   */
  static forRoot(config: any): ModuleWithProviders<MxvConfigModule> {
    return {
      ngModule: MxvConfigModule,
      providers: [
        {
          provide: MXV_APP_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
